
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
		"Open Sans", "Helvetica Neue", sans-serif;
	height: 100vh;
}

/* @layer components {
	.preferenceBtn {
		@apply relative rounded px-3 py-1.5 font-medium items-center transition-all focus:outline-none inline-flex  ml-auto p-1 mr-2 hover:bg-dark-fill-3;
	}

	.preferenceBtn-tooltip {
		@apply absolute w-auto p-2 text-sm m-2  min-w-max translate-x-3  right-0 top-5 z-10 rounded-md shadow-md
		text-dark-layer-2 bg-gray-200  origin-center scale-0 transition-all duration-100 ease-linear group-hover:scale-100;
	}
} */

/* dark  scrollbar */
::-webkit-scrollbar {
	width: 0.5rem;
}

::-webkit-scrollbar-track {
	background: #282828;
}

::-webkit-scrollbar-thumb {
	background: #4d4d4d;
}

/* CODE EDITOR*/
.cm-scroller,
.cm-gutters {
	background-color: #282828 !important;
}
.cm-focused {
	outline: none !important;
}

.split {
	display: flex;
	flex-direction: row;
}

.gutter {
	background-color: 000(26, 26, 26);
	background-repeat: no-repeat;
	background-position: 50%;
}
.gutter:hover {
	background-color: #0a84ff;
}

.gutter.gutter-horizontal {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
	cursor: col-resize;
}

.gutter.gutter-vertical {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
	cursor: row-resize;
}

code {
	border-radius: 5px;
	border-width: 1px;
	font-family: sans-serif;
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1rem;
	padding: 0.125rem;
	letter-spacing: 1px;
	white-space: pre-wrap;
	background-color: hsla(0, 0%, 100%, 0.07);
	border-color: rgba(247, 250, 255, 0.12);
	color: rgba(239, 241, 246, 0.75);
}

.example-card pre {
	background-color: hsla(0, 0%, 100%, 0.1);
	border-radius: 0.5rem;
	color: rgba(239, 241, 246, 0.75);
	font-size: 0.98rem;
	line-height: 1.25rem;
	margin-bottom: 1rem;
	margin-top: 1rem;
	padding: 1rem;
	white-space: pre-wrap;
}
.example-card pre strong {
	color: white;
	font-size: 1rem;
}

button.btn.btn-primary {
	color: #0d6efd;
}

button.btn.btn-secondary {
	color: #6c757d!important;
}